"use client";

import * as React from "react";

import type { Trade } from "#src/graphql/gql-types";

interface TradesHistoryItemsContextType {
  tradeHistoryItems: Trade[];
  setTradeHistoryItems: React.Dispatch<React.SetStateAction<Trade[]>>;
}

const TradesHistoryItemsContext = React.createContext<
  TradesHistoryItemsContextType | undefined
>(undefined);

export const useTradesHistoryItems = () => {
  const context = React.useContext(TradesHistoryItemsContext);

  if (context === undefined) {
    throw new Error(
      "useTradesHistoryItems must be used within an TradesHistoryItemsProvider"
    );
  }

  return context;
};

export const TradesHistoryItemsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tradeHistoryItems, setTradeHistoryItems] = React.useState<Trade[]>([]);

  return (
    <TradesHistoryItemsContext.Provider
      value={{ tradeHistoryItems, setTradeHistoryItems }}
    >
      {children}
    </TradesHistoryItemsContext.Provider>
  );
};
