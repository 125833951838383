"use client";

import * as React from "react";

import type { Client } from "#src/graphql/gql-types";

interface ActiveClientContextType {
  activeClient: Client | null;
}

const ActiveClientContext = React.createContext<
  ActiveClientContextType | undefined
>(undefined);

export const useActiveClient = () => {
  const context = React.useContext(ActiveClientContext);

  if (context === undefined) {
    throw new Error("useActiveUser must be used within an ActiveUserProvider");
  }

  return context;
};

export const ActiveClientProvider = ({
  children,
  activeClient,
}: {
  children: React.ReactNode;
  activeClient: Client | null;
}) => {
  return (
    <ActiveClientContext.Provider value={{ activeClient }}>
      {children}
    </ActiveClientContext.Provider>
  );
};
