"use client";

import {
  ChevronDownIcon,
  LogoutIcon,
  MenuIcon,
  SwitchHorizontalIcon,
  UserIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/nextjs";
import { signOut } from "aws-amplify/auth";
import Link from "next/link";
import { useRouter } from "next/navigation";
import * as React from "react";
import useWindowEvent from "../../../../hooks/useWindowEvent";
import { setCookie } from "../../../../lib/cookie";

type ActiveClient = {
  ctc_first_name: string;
  ctc_last_name: string;
  cli_name?: string;
};

export type HeaderProps = {
  activeClient: ActiveClient;
  showAccountSwitch: boolean;
  isSidebarOpen: boolean;
  toggleSideBar: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Header({
  showAccountSwitch = false,
  isSidebarOpen: isSidebarOpened,
  toggleSideBar,
  activeClient,
}: HeaderProps) {
  const router = useRouter();
  const [userMenuOpen, setUserMenuOpen] = React.useState<boolean>(false);

  async function onSignOut(event: React.SyntheticEvent) {
    event.preventDefault();

    // Tricky to log this as the user credentials are no longer available
    // logWebActivity({
    //   activeClient,
    //   action: "LOGOUT",
    //   id: generateUUID(),
    //   message: "logged out",
    // });

    Sentry.setUser(null);

    setCookie("activeClient", "", 0, true);

    await signOut();

    router.push("/login");
  }

  const accountName =
    activeClient?.cli_name ||
    `${activeClient?.ctc_first_name} ${activeClient?.ctc_last_name}`;

  useWindowEvent("click", (ev) => {
    const $button: Element = ev.target as Element;

    if ($button.id !== "profile-menu") {
      setUserMenuOpen(false);
    }
  });

  return (
    <header className="relative z-10 flex-shrink-0 flex h-14 bg-theme-color-surface shadow">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        onClick={() => toggleSideBar(() => !isSidebarOpened)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex-1 px-4 flex items-center justify-end">
        <div className="relative">
          <button
            id="profile-menu"
            type="button"
            className="flex items-center"
            onClick={() => setUserMenuOpen(!userMenuOpen)}
          >
            <span className="rounded-full text-white font-bold bg-gray-500 p-1.5 mr-2">
              {[
                accountName?.split(" ")[0][0]?.toUpperCase(),
                accountName?.split(" ")[1][0]?.toUpperCase(),
              ].join(" ")}
            </span>

            {accountName}

            <ChevronDownIcon className="h-5 w-5 ml-2" />
          </button>

          <div
            className={`${
              userMenuOpen ? "" : "hidden"
            } origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-gray-100 focus:outline-none`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
          >
            <Link
              href="/profile"
              className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end"
            >
              Profile <UserIcon className="h-5 w-5 ml-2" />
            </Link>

            {showAccountSwitch && (
              <Link
                href="/choose-account"
                className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end border-t border-gray-200"
              >
                Switch account <SwitchHorizontalIcon className="h-5 w-5 ml-2" />
              </Link>
            )}

            <a
              href="#"
              onClick={onSignOut}
              className="flex px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 justify-end border-t border-gray-200"
            >
              Sign out <LogoutIcon className="h-5 w-5 ml-2" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
