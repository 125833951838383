"use client";

import assert from "assert";
import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";
import NavItem from "./NavItem/NavItem";
import {
  GlobeAltIcon,
  HomeIcon,
  SupportIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import type { Route } from "next";
import Button from "../button/Button";

// TODO: remove the app prefix when we are ready with the app router migration !
const navigation = [
  { href: "/", icon: HomeIcon, text: "Dashboard" },
  { href: "/beneficiaries", icon: UserCircleIcon, text: "Beneficiaries" },
  { href: "/transactions", icon: GlobeAltIcon, text: "My transactions" },
  { href: "/help", icon: SupportIcon, text: "Help and Support" },
];

export type Notifications = {
  paymentsRequiringApproval: boolean;
  beneficiariesRequiringApproval: boolean;
};

type SidebarProps = {
  isOpen: boolean;
  toggleSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  notifications?: Notifications;
};

export default function Sidebar({
  isOpen,
  toggleSideBar,
  notifications,
}: SidebarProps) {
  assert(typeof isOpen === "boolean", "isOpen must be true or false");
  assert(
    typeof toggleSideBar === "function",
    "toggleSideBar must be a function"
  );

  const router = useRouter();
  const pathname = usePathname();

  return (
    <>
      <div
        data-ui="Sidebar"
        data-testid="sidebar"
        className={`w-64 ${
          isOpen
            ? "fixed inset-0 flex z-40 flex-shrink-0"
            : "hidden lg:flex lg:flex-shrink-0"
        }`}
      >
        <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-teal-700">
          <div className="absolute top-0 right-0 pt-2 -mr-12">
            <button
              type="button"
              className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              data-testid="sidebarToggle"
              onClick={() => toggleSideBar(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
            </button>
          </div>

          <Link href="/" tabIndex={0}>
            <img
              onClick={() => toggleSideBar(false)}
              onKeyDown={(e) => e.key === "Enter" && toggleSideBar(false)}
              className="mx-auto fill-current"
              src="/next/assets/clear_symbol_light.svg"
              alt="Clear Currency"
              data-testid="sidebar-logo"
            />
          </Link>

          <div className="flex flex-col p-4">
            <Button
              href="/transfer"
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                toggleSideBar(false);
                router.push(e.currentTarget.href as Route);
              }}
            >
              Make a transfer
            </Button>
          </div>

          <nav
            style={{ overflowY: "auto" }}
            className="pt-4 border-t border-teal-400"
          >
            <ul>
              {navigation.map(({ href, icon: Icon, text }) => (
                <li
                  key={href}
                  onKeyDown={(e) => e.key === "Enter" && toggleSideBar(false)}
                  onClick={() => toggleSideBar(false)}
                  className={
                    href === "/help" ? "border-t border-teal-400 mt-4 pt-4" : ""
                  }
                >
                  <NavItem
                    href={href as Route}
                    text={text}
                    icon={<Icon />}
                    isActive={href === `/${pathname?.split("/")[1]}`}
                    notifications={
                      (text === "Dashboard" &&
                        notifications?.paymentsRequiringApproval) ||
                      (text === "Beneficiaries" &&
                        notifications?.beneficiariesRequiringApproval)
                    }
                  />
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {isOpen && (
        // Full-screen app overlay
        <div
          className="fixed inset-0 z-30 bg-gray-600 opacity-75 lg:hidden"
          aria-hidden="true"
        />
      )}
    </>
  );
}
