export function setCookie(
  name: string,
  value: string,
  days: number = 30,
  expired?: boolean
) {
  const attributes = [];

  if (expired) {
    attributes.push("expires=Thu, 01 Jan 1970 00:00:00 GMT");
  } else if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    attributes.push(`expires=${date.toUTCString()}`);
    attributes.push(`max-age=${days * 24 * 60 * 60}`);
  }

  attributes.push("path=/");
  if (process.env.NODE_ENV === "production") {
    attributes.push("Secure");
  }
  attributes.push("SameSite=Lax");

  if (typeof window?.document !== "undefined") {
    document.cookie = `${name}=${value || ""}; ${attributes.join("; ")}`;
  }

  console.log("attributes >>>>>>>>>>>>>>>>>>>>", attributes.join("; "));
}
