"use client";

import React from "react";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";

import type { Client } from "../../../../graphql/gql-types";
import type { Notifications } from "../sidebar/Sidebar";

type AppProps = {
  clientCount: number;
  activeClient: Client;
  children: React.ReactNode;
  notifications: Notifications;
};

export default function App({
  clientCount,
  activeClient,
  notifications,
  children,
}: AppProps) {
  const [isSidebarOpen, toggleSideBar] = React.useState<boolean>(false);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSideBar={toggleSideBar}
        notifications={notifications}
      />

      <div className="flex flex-col flex-1 w-0">
        <Header
          activeClient={activeClient}
          showAccountSwitch={clientCount > 1}
          isSidebarOpen={isSidebarOpen}
          toggleSideBar={toggleSideBar}
        />
        <div className="overflow-y-scroll">{children}</div>
      </div>
    </div>
  );
}
