import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/app/_components/aws-amplify/ConfigureAmplifyClientSide.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/app/(app)/_components/app/App.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ActiveClientProvider"] */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/app/(app)/ActiveClientContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/ctx/ToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TradesHistoryItemsProvider"] */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/ctx/TradesHistoryItemsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/payments-platform-web-app/payments-platform-web-app/src/styles.css");
