"use client";

import { useEffect } from "react";

const useWindowEvent = (
  type: string,
  callback: (ev: MouseEvent) => void,
  dependencyArray: Array<any> = []
): void => {
  useEffect(() => {
    window.addEventListener(type, callback, true);

    return () => window.removeEventListener(type, callback, true);
  }, dependencyArray);
};

export default useWindowEvent;
